const countries = [
  { id: 1, country_name: "Afghanistan" },
  { id: 2, country_name: "Albania" },
  { id: 3, country_name: "Algeria" },
  { id: 4, country_name: "Andorra" },
  { id: 5, country_name: "Angola" },
  { id: 6, country_name: "Antigua and Barbuda" },
  { id: 7, country_name: "Argentina" },
  { id: 8, country_name: "Armenia" },
  { id: 9, country_name: "Australia" },
  { id: 10, country_name: "Austria" },
  { id: 11, country_name: "Azerbaijan" },
  { id: 12, country_name: "Bahamas" },
  { id: 13, country_name: "Bahrain" },
  { id: 14, country_name: "Bangladesh" },
  { id: 15, country_name: "Barbados" },
  { id: 16, country_name: "Belarus" },
  { id: 17, country_name: "Belgium" },
  { id: 18, country_name: "Belize" },
  { id: 19, country_name: "Benin" },
  { id: 20, country_name: "Bhutan" },
  { id: 21, country_name: "Bolivia" },
  { id: 22, country_name: "Bosnia and Herzegovina" },
  { id: 23, country_name: "Botswana" },
  { id: 24, country_name: "Brazil" },
  { id: 25, country_name: "Brunei" },
  { id: 26, country_name: "Bulgaria" },
  { id: 27, country_name: "Burkina Faso" },
  { id: 28, country_name: "Burundi" },
  { id: 29, country_name: "Cabo Verde" },
  { id: 30, country_name: "Cambodia" },
  { id: 31, country_name: "Cameroon" },
  { id: 32, country_name: "Canada" },
  { id: 33, country_name: "Central African Republic" },
  { id: 34, country_name: "Chad" },
  { id: 35, country_name: "Chile" },
  { id: 36, country_name: "China" },
  { id: 37, country_name: "Colombia" },
  { id: 38, country_name: "Comoros" },
  { id: 39, country_name: "Congo (Congo-Brazzaville)" },
  { id: 40, country_name: "Congo (Congo-Kinshasa)" },
  { id: 41, country_name: "Costa Rica" },
  { id: 42, country_name: "Croatia" },
  { id: 43, country_name: "Cuba" },
  { id: 44, country_name: "Cyprus" },
  { id: 45, country_name: "Czech Republic" },
  { id: 46, country_name: "Denmark" },
  { id: 47, country_name: "Djibouti" },
  { id: 48, country_name: "Dominica" },
  { id: 49, country_name: "Dominican Republic" },
  { id: 50, country_name: "Ecuador" },
  { id: 51, country_name: "Egypt" },
  { id: 52, country_name: "El Salvador" },
  { id: 53, country_name: "Equatorial Guinea" },
  { id: 54, country_name: "Eritrea" },
  { id: 55, country_name: "Estonia" },
  { id: 56, country_name: "Eswatini" },
  { id: 57, country_name: "Ethiopia" },
  { id: 58, country_name: "Fiji" },
  { id: 59, country_name: "Finland" },
  { id: 60, country_name: "France" },
  { id: 61, country_name: "Gabon" },
  { id: 62, country_name: "Gambia" },
  { id: 63, country_name: "Georgia" },
  { id: 64, country_name: "Germany" },
  { id: 65, country_name: "Ghana" },
  { id: 66, country_name: "Greece" },
  { id: 67, country_name: "Grenada" },
  { id: 68, country_name: "Guatemala" },
  { id: 69, country_name: "Guinea" },
  { id: 70, country_name: "Guinea-Bissau" },
  { id: 71, country_name: "Guyana" },
  { id: 72, country_name: "Haiti" },
  { id: 73, country_name: "Honduras" },
  { id: 74, country_name: "Hungary" },
  { id: 75, country_name: "Iceland" },
  { id: 76, country_name: "India" },
  { id: 77, country_name: "Indonesia" },
  { id: 78, country_name: "Iran" },
  { id: 79, country_name: "Iraq" },
  { id: 80, country_name: "Ireland" },
  { id: 81, country_name: "Israel" },
  { id: 82, country_name: "Italy" },
  { id: 83, country_name: "Jamaica" },
  { id: 84, country_name: "Japan" },
  { id: 85, country_name: "Jordan" },
  { id: 86, country_name: "Kazakhstan" },
  { id: 87, country_name: "Kenya" },
  { id: 88, country_name: "Kiribati" },
  { id: 89, country_name: "Korea (North)" },
  { id: 90, country_name: "Korea (South)" },
  { id: 91, country_name: "Kuwait" },
  { id: 92, country_name: "Kyrgyzstan" },
  { id: 93, country_name: "Laos" },
  { id: 94, country_name: "Latvia" },
  { id: 95, country_name: "Lebanon" },
  { id: 96, country_name: "Lesotho" },
  { id: 97, country_name: "Liberia" },
  { id: 98, country_name: "Libya" },
  { id: 99, country_name: "Liechtenstein" },
  { id: 100, country_name: "Lithuania" },
  { id: 101, country_name: "Luxembourg" },
  { id: 102, country_name: "Madagascar" },
  { id: 103, country_name: "Malawi" },
  { id: 104, country_name: "Malaysia" },
  { id: 105, country_name: "Maldives" },
  { id: 106, country_name: "Mali" },
  { id: 107, country_name: "Malta" },
  { id: 108, country_name: "Marshall Islands" },
  { id: 109, country_name: "Mauritania" },
  { id: 110, country_name: "Mauritius" },
  { id: 111, country_name: "Mexico" },
  { id: 112, country_name: "Micronesia" },
  { id: 113, country_name: "Moldova" },
  { id: 114, country_name: "Monaco" },
  { id: 115, country_name: "Mongolia" },
  { id: 116, country_name: "Montenegro" },
  { id: 117, country_name: "Morocco" },
  { id: 118, country_name: "Mozambique" },
  { id: 119, country_name: "Myanmar" },
  { id: 120, country_name: "Namibia" },
  { id: 121, country_name: "Nauru" },
  { id: 122, country_name: "Nepal" },
  { id: 123, country_name: "Netherlands" },
  { id: 124, country_name: "New Zealand" },
  { id: 125, country_name: "Nicaragua" },
  { id: 126, country_name: "Niger" },
  { id: 127, country_name: "Nigeria" },
  { id: 128, country_name: "North Macedonia" },
  { id: 129, country_name: "Norway" },
  { id: 130, country_name: "Oman" },
  { id: 131, country_name: "Pakistan" },
  { id: 132, country_name: "Palau" },
  { id: 133, country_name: "Palestine" },
  { id: 134, country_name: "Panama" },
  { id: 135, country_name: "Papua New Guinea" },
  { id: 136, country_name: "Paraguay" },
  { id: 137, country_name: "Peru" },
  { id: 138, country_name: "Philippines" },
  { id: 139, country_name: "Poland" },
  { id: 140, country_name: "Portugal" },
  { id: 141, country_name: "Qatar" },
  { id: 142, country_name: "Romania" },
  { id: 143, country_name: "Russia" },
  { id: 144, country_name: "Rwanda" },
  { id: 145, country_name: "Saint Kitts and Nevis" },
  { id: 146, country_name: "Saint Lucia" },
  { id: 147, country_name: "Saint Vincent and the Grenadines" },
  { id: 148, country_name: "Samoa" },
  { id: 149, country_name: "San Marino" },
  { id: 150, country_name: "Sao Tome and Principe" },
  { id: 151, country_name: "Saudi Arabia" },
  { id: 152, country_name: "Senegal" },
  { id: 153, country_name: "Serbia" },
  { id: 154, country_name: "Seychelles" },
  { id: 155, country_name: "Sierra Leone" },
  { id: 156, country_name: "Singapore" },
  { id: 157, country_name: "Slovakia" },
  { id: 158, country_name: "Slovenia" },
  { id: 159, country_name: "Solomon Islands" },
  { id: 160, country_name: "Somalia" },
  { id: 161, country_name: "South Africa" },
  { id: 162, country_name: "South Sudan" },
  { id: 163, country_name: "Spain" },
  { id: 164, country_name: "Sri Lanka" },
  { id: 165, country_name: "Sudan" },
  { id: 166, country_name: "Suriname" },
  { id: 167, country_name: "Sweden" },
  { id: 168, country_name: "Switzerland" },
  { id: 169, country_name: "Syria" },
  { id: 170, country_name: "Taiwan" },
  { id: 171, country_name: "Tajikistan" },
  { id: 172, country_name: "Tanzania" },
  { id: 173, country_name: "Thailand" },
  { id: 174, country_name: "Timor-Leste" },
  { id: 175, country_name: "Togo" },
  { id: 176, country_name: "Tonga" },
  { id: 177, country_name: "Trinidad and Tobago" },
  { id: 178, country_name: "Tunisia" },
  { id: 179, country_name: "Turkey" },
  { id: 180, country_name: "Turkmenistan" },
  { id: 181, country_name: "Tuvalu" },
  { id: 182, country_name: "Uganda" },
  { id: 183, country_name: "Ukraine" },
  { id: 184, country_name: "United Arab Emirates" },
  { id: 185, country_name: "United Kingdom" },
  { id: 186, country_name: "United States" },
  { id: 187, country_name: "Uruguay" },
  { id: 188, country_name: "Uzbekistan" },
  { id: 189, country_name: "Vanuatu" },
  { id: 190, country_name: "Venezuela" },
  { id: 191, country_name: "Vietnam" },
  { id: 192, country_name: "Yemen" },
  { id: 193, country_name: "Zambia" },
  { id: 194, country_name: "Zimbabwe" },
];

export default countries;
