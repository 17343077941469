import React, { useState, useRef } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { Image, Paperclip } from "lucide-react";
import IconButton from "../IconButton";
import { useWindowSize } from "../../utils";
import AvatarChat from "../../assets/image/AvatarChat.png";
import AvatarChatUser from "../../assets/image/AvatarProfil.png";
import UserMessage from "./UserMessage";
import UserDialogue from "./UserDialogue";
import { SelectAuth } from "../../redux/selectors";
import { useParams } from "react-router-dom";
import ChatWindow from "../ChatWindow";
const ChatContainer = styled.section`
  width: 100%;
  height: ${(props) => props.size - 70}px;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
`;

const ProfileWrapper = styled.section`
  background: #f8f9fa;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  padding-right: 16px;
  font-family: "Lato", sans-serif;
  height: 100vh;
`;

const ProfileWrapperDialogue = styled.section`
  background: #f8f9fa;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  font-family: "Lato", sans-serif;
  height: 100vh;
`;

function Dialogue() {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [messages, setMessages] = useState();
  const [selectChat, setSelectChat] = useState(
    ["manager"].includes(user?.role) ? true : false
  );
  const [inputMessage, setInputMessage] = useState("");
  const [userMessageName, setUserMessageName] = useState("Антон Букин");
  const fileInputRef = useRef(null);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      const newMessage = {
        id: messages.length + 1,
        text: inputMessage,
        isUser: true,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages([...messages, newMessage]);
      setInputMessage("");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newMessage = {
          id: messages.length + 1,
          isUser: true,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          file: {
            name: file.name,
            type: file.type,
            url: e.target.result,
          },
        };
        setMessages([...messages, newMessage]);
      };
      reader.readAsDataURL(file);
    }
  };
  const { id } = useParams();

  return (
    <>
      <ProfileWrapperDialogue>
        {selectChat ? (
          <UserDialogue
            setSelectChat={setSelectChat}
            selectChat={setMessages}
            userNameChat={setUserMessageName}
            managerId={selectChat}
          />
        ) : (
          <ProfileWrapper>
            <UserMessage setSelectChat={setSelectChat} />
          </ProfileWrapper>
        )}
      </ProfileWrapperDialogue>
      <ChatContainer size={height}>
        {id ? (
          <ChatWindow />
        ) : (
          <h1
            style={{
              textAlign: "center",
              paddingTop: "30vh",
            }}
          >
            Пожалуйста, выберите один из чатов!
          </h1>
        )}
      </ChatContainer>
    </>
  );
}

export default Dialogue;
