import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { KanbaInfoIcon } from "../../../assets/icon";
import axios from "axios";
import { base_url } from "../../../utils/request";
import { platforms } from "../../../utils";
import request from "../../../utils/request";
import toast from "react-hot-toast";

const FormContainer = styled.div`
  border-radius: 8px;
  padding: 24px;
  font-family: "Arial", sans-serif;
`;

const FormTitle = styled.h2`
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const InputGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  border: 1px solid ${(props) => (props.error ? "#ff0000" : "#e0e0e0")};
  border-radius: 4px;
  padding: 0 12px;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  background: #f5f5f5;
  border: 1px solid ${(props) => (props.error ? "#ff0000" : "#e0e0e0")};
  border-radius: 4px;
  padding: 0 12px;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  border: 1px solid ${(props) => (props.error ? "#ff0000" : "#e0e0e0")};
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  resize: vertical;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: #6366f1;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

const FileUploadContainer = styled.div`
  border: 2px dashed ${(props) => (props.isDragActive ? "#6366f1" : "#e0e0e0")};
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  color: #666;
  margin-bottom: 16px;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: #6366f1;
  }
`;

const UploadButton = styled.button`
  background-color: #f3f4f6;
  color: #6366f1;
  border: 1px solid #6366f1;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 8px;
`;

const FilePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const FilePreview = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const FileImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const FileIcon = styled.div`
  font-size: 40px;
  color: #6366f1;
`;

const FileName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveFileButton = styled.button`
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(255, 0, 0, 0.8);
    color: white;
  }
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #6366f1;
  color: #ffffff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4f46e5;
  }

  &:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
  }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const Column = styled.div`
  flex: 1;
`;

const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const InfoText = styled.div`
  margin-left: 10px;
`;

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
`;

const TaskForm = ({ CloseModal, isEdit = false, initialData = {} }) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      eventName: initialData.title || "",
      urgency: initialData.priority || "",
      status: initialData.status || "",
      startDate: initialData.start_date
        ? initialData.start_date.slice(0, 10)
        : "",
      endDate: initialData.end_date ? initialData.end_date.slice(0, 10) : "",
      platform: initialData.platform || "",
      maxExpense: initialData.max_expense || 0,
      expectedIncome: initialData.expected_income || 0,
      notifications: initialData.reminders === "1",
      description: initialData.description || "",
      responsible: initialData.responsible_person || "",
      // Initialize other fields if needed
    },
  });

  const [files, setFiles] = useState(
    initialData.files
      ? initialData.files.map((file) => ({
          ...file,
          preview: file.url, // Assuming each file has a 'url' property
        }))
      : []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  // Fetch users from the backend
  const fetchUsers = async () => {
    try {
      const response = await request.get("/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    ]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Remove a file from the list
  const removeFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  // Render file previews
  const renderFilePreview = (file) => {
    if (file.url) {
      // Existing file from initialData
      return (
        <FilePreview key={file.id || file.name}>
          <FileImage src={file.preview} alt={file.name} />
          <FileName>{file.name}</FileName>
          <RemoveFileButton onClick={() => removeFile(file)}>
            ×
          </RemoveFileButton>
        </FilePreview>
      );
    } else if (file.type.startsWith("image/")) {
      return (
        <FilePreview key={file.name}>
          <FileImage src={file.preview} alt={file.name} />
          <FileName>{file.name}</FileName>
          <RemoveFileButton onClick={() => removeFile(file)}>
            ×
          </RemoveFileButton>
        </FilePreview>
      );
    } else {
      return (
        <FilePreview key={file.name}>
          <FileIcon>📄</FileIcon>
          <FileName>{file.name}</FileName>
          <RemoveFileButton onClick={() => removeFile(file)}>
            ×
          </RemoveFileButton>
        </FilePreview>
      );
    }
  };

  // Handle form submission
  const onSubmit = async (data) => {
    setIsLoading(true);

    // Create FormData for file uploads
    const formData = new FormData();
    const startDate = new Date(data.startDate).toISOString().slice(0, 10);
    const endDate = new Date(data.endDate).toISOString().slice(0, 10);

    // Add form fields to formData
    formData.append("title", data.eventName);
    formData.append("priority", data.urgency);
    formData.append("status", data.status);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("platform", data.platform);
    formData.append("max_expense", data.maxExpense);
    formData.append("expected_income", data.expectedIncome);
    formData.append("reminders", data.notifications ? "1" : "0");
    formData.append("description", data.description);
    formData.append("responsible_person", data.responsible);

    // Add new files to formData
    files.forEach((file) => {
      if (!file.url) {
        // Only append new files
        formData.append("file", file);
      }
    });

    try {
      const token = localStorage.getItem("token"); // Get token if required

      let response;
      if (isEdit) {
        // API call to update a task
        response = await axios.post(
          base_url + "/taskUpdate/" + initialData.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        // API call to create a task
        response = await axios.post(base_url + "/tasks", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
      }

      toast.success(`Task ${isEdit ? "updated" : "created"} successfully`);
      // Optionally reset the form or perform other actions
      if (!isEdit) {
        reset();
        setFiles([]);
      }
    } catch (error) {
      toast.error(`Error ${isEdit ? "updating" : "creating"} task:`);
      // Optionally show error messages to the user
    } finally {
      setIsLoading(false);
      CloseModal(); // Close the modal after task creation/update
    }
  };

  // Cleanup file previews to avoid memory leaks
  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file.preview && !file.url) {
          // Only revoke object URLs for new files
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [files]);

  return (
    <FormContainer>
      <FormTitle>
        <InfoDiv>
          <KanbaInfoIcon />{" "}
          <InfoText>
            {isEdit ? "Редактировать задачу" : "Общая информация"}
          </InfoText>
        </InfoDiv>
      </FormTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <Label>Название события</Label>
          <Controller
            name="eventName"
            control={control}
            defaultValue={initialData.title || ""}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Input {...field} error={errors.eventName} />
            )}
          />
          {errors.eventName && (
            <ErrorMessage>{errors.eventName.message}</ErrorMessage>
          )}
        </InputGroup>

        {/* Other form fields... */}
        {/* The rest of the form fields remain largely unchanged, utilizing defaultValues from initialData */}

        <Row>
          <Column>
            <InputGroup>
              <Label>Уровень срочности</Label>
              <Controller
                name="urgency"
                control={control}
                defaultValue={initialData.priority || ""}
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => (
                  <Select {...field} error={errors.urgency}>
                    <option value="">Выберите уровень</option>
                    <option value="Высокий">Высокий</option>
                    <option value="Средний">Средний</option>
                    <option value="Низкий">Низкий</option>
                  </Select>
                )}
              />
              {errors.urgency && (
                <ErrorMessage>{errors.urgency.message}</ErrorMessage>
              )}
            </InputGroup>
          </Column>
          <Column>
            <InputGroup>
              <Label>Статус</Label>
              <Controller
                name="status"
                control={control}
                defaultValue={initialData.status || ""}
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => (
                  <Select {...field} error={errors.status}>
                    <option value="">Выберите статус</option>
                    <option value="Делать">Делать</option>
                    <option value="В ходе выполнения">В ходе выполнения</option>
                    <option value="Не выполненно">Не выполненно</option>
                    <option value="Сделанный">Сделанный</option>
                  </Select>
                )}
              />
              {errors.status && (
                <ErrorMessage>{errors.status.message}</ErrorMessage>
              )}
            </InputGroup>
          </Column>
        </Row>
        <Row>
          <Column>
            <InputGroup>
              <Label>Дата начала</Label>
              <Controller
                name="startDate"
                control={control}
                defaultValue={
                  initialData.start_date
                    ? initialData.start_date.slice(0, 10)
                    : ""
                }
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => (
                  <Input {...field} type="date" error={errors.startDate} />
                )}
              />
              {errors.startDate && (
                <ErrorMessage>{errors.startDate.message}</ErrorMessage>
              )}
            </InputGroup>
          </Column>
          <Column>
            <InputGroup>
              <Label>Дата окончания</Label>
              <Controller
                name="endDate"
                control={control}
                defaultValue={
                  initialData.end_date ? initialData.end_date.slice(0, 10) : ""
                }
                rules={{ required: "Это поле обязательно" }}
                render={({ field }) => (
                  <Input {...field} type="date" error={errors.endDate} />
                )}
              />
              {errors.endDate && (
                <ErrorMessage>{errors.endDate.message}</ErrorMessage>
              )}
            </InputGroup>
          </Column>
        </Row>
        <InputGroup>
          <Label>Площадка</Label>
          <Controller
            name="platform"
            control={control}
            defaultValue={initialData.platform || ""}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Select {...field} error={errors.platform}>
                <option value="">Выберите площадку</option>
                {platforms.map((item, i) => (
                  <option value={item} key={i}>
                    {item}
                  </option>
                ))}
              </Select>
            )}
          />
          {errors.platform && (
            <ErrorMessage>{errors.platform.message}</ErrorMessage>
          )}
        </InputGroup>
        <InputGroup>
          <Label>Максимальный расход на компанию (₽)</Label>
          <Controller
            name="maxExpense"
            control={control}
            defaultValue={initialData.max_expense || 0}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Input {...field} type="number" error={errors.maxExpense} />
            )}
          />
          {errors.maxExpense && (
            <ErrorMessage>{errors.maxExpense.message}</ErrorMessage>
          )}
        </InputGroup>
        <InputGroup>
          <Label>Ожидаемый доход (₽)</Label>
          <Controller
            name="expectedIncome"
            control={control}
            defaultValue={initialData.expected_income || 0}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Input {...field} type="number" error={errors.expectedIncome} />
            )}
          />
          {errors.expectedIncome && (
            <ErrorMessage>{errors.expectedIncome.message}</ErrorMessage>
          )}
        </InputGroup>
        <ToggleContainer>
          <Label>Напоминания и уведомления о сроках выполнения</Label>
          <Toggle>
            <Controller
              name="notifications"
              control={control}
              defaultValue={initialData.reminders === "1"}
              render={({ field }) => <input type="checkbox" {...field} />}
            />
            <span></span>
          </Toggle>
        </ToggleContainer>
        <InputGroup>
          <Label>Описание</Label>
          <Controller
            name="description"
            control={control}
            defaultValue={initialData.description || ""}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Textarea {...field} error={errors.description} />
            )}
          />
          {errors.description && (
            <ErrorMessage>{errors.description.message}</ErrorMessage>
          )}
        </InputGroup>
        <InputGroup>
          <Label>Документы</Label>
          <FileUploadContainer {...getRootProps()} isDragActive={isDragActive}>
            <input {...getInputProps()} />
            <UploadButton type="button">Добавить файл</UploadButton>
            <p>или перетащите файлы сюда</p>
          </FileUploadContainer>
          {files.length > 0 && (
            <FilePreviewContainer>
              {files.map(renderFilePreview)}
            </FilePreviewContainer>
          )}
        </InputGroup>
        <InputGroup>
          <Label>Ответственное лицо</Label>
          <Controller
            name="responsible"
            control={control}
            defaultValue={initialData.responsible_person || ""}
            rules={{ required: "Это поле обязательно" }}
            render={({ field }) => (
              <Select {...field} error={errors.responsible}>
                <option value="">Выберите ответственное лицо</option>
                {users?.map((item, i) => (
                  <option value={item.id} key={i}>
                    {item.name}
                  </option>
                ))}
              </Select>
            )}
          />
          {errors.responsible && (
            <ErrorMessage>{errors.responsible.message}</ErrorMessage>
          )}
        </InputGroup>

        <SubmitButton type="submit" disabled={isSubmitting || isLoading}>
          {isLoading ? (
            <>
              {isEdit ? "Обновление..." : "Загрузка..."}
            </>
          ) : isEdit ? (
            "Обновить"
          ) : (
            "Сохранить"
          )}
        </SubmitButton>
      </form>
    </FormContainer>
  );
};

export default TaskForm;
