import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Calendar, ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";

const DatePickerWrapper = styled.div`
  position: relative;
`;

const DateRangeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  min-width: 240px;
`;

const DatePickerPopup = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  display: flex;
  gap: 16px;
`;

const DatePickerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateRangeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const DateRangeInput = styled.input`
  padding: 4px 8px;
  border: 1px solid #bcc1ca;
  border-radius: 4px;
  font-size: 14px;
  width: 120px;
`;

const ApplyButton = styled.button`
  background-color: #5469d4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 8px;
`;

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const MonthYearDisplay = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DaysHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  margin-bottom: 8px;
`;

const DayLabel = styled.span`
  text-align: center;
  color: #5469d4;
  font-size: 14px;
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
`;

const DayButton = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  background: ${(props) => (props.isSelected ? "#5469d4" : "transparent")};
  color: ${(props) =>
    props.isSelected ? "white" : props.isCurrentMonth ? "black" : "#c0c0c0"};
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${(props) => (props.isSelected ? "#5469d4" : "#f0f0f0")};
  }
`;

const DatePicker = ({ selectedDate, onChange }) => {
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(currentYear, currentMonth, i));
  }

  const previousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div>
      <DatePickerHeader>
        <NavButton onClick={previousMonth}>
          <ChevronLeft size={24} />
        </NavButton>
        <MonthYearDisplay>
          {new Date(currentYear, currentMonth).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </MonthYearDisplay>
        <NavButton onClick={nextMonth}>
          <ChevronRight size={24} />
        </NavButton>
      </DatePickerHeader>
      <DaysHeader>
        {["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((day) => (
          <DayLabel key={day}>{day}</DayLabel>
        ))}
      </DaysHeader>
      <DaysGrid>
        {Array(firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1)
          .fill(null)
          .map((_, index) => (
            <div key={`empty-${index}`} />
          ))}
        {days.map((day) => (
          <DayButton
            key={day}
            onClick={() => onChange(day)}
            isSelected={day.toDateString() === selectedDate.toDateString()}
            isCurrentMonth={true}
          >
            {day.getDate()}
          </DayButton>
        ))}
      </DaysGrid>
    </div>
  );
};

const DateRangePicker = ({ initialStartDate, initialEndDate, onApply }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDateRange, setTempDateRange] = useState({
    start: initialStartDate,
    end: initialEndDate,
  });
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateRangeChange = (startOrEnd, date) => {
    setTempDateRange((prev) => ({
      ...prev,
      [startOrEnd]: date,
    }));
  };

  const applyDateRange = () => {
    onApply(tempDateRange);
    setShowDatePicker(false);
  };

  const formatDateRange = (start, end) => {
    const formatDate = (date) => {
      return date.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <DatePickerWrapper ref={datePickerRef}>
      <DateRangeButton onClick={toggleDatePicker}>
        <Calendar size={16} />
        {formatDateRange(tempDateRange.start, tempDateRange.end)}
        <ChevronDown size={16} />
      </DateRangeButton>
      {showDatePicker && (
        <DatePickerPopup>
          <DatePickerColumn>
            <DateRangeDisplay>
              <DateRangeInput
                type="text"
                value={tempDateRange.start.toLocaleDateString()}
                readOnly
              />
              <span>-</span>
              <DateRangeInput
                type="text"
                value={tempDateRange.end.toLocaleDateString()}
                readOnly
              />
            </DateRangeDisplay>
            <DatePicker
              selectedDate={tempDateRange.start}
              onChange={(date) => handleDateRangeChange("start", date)}
            />
          </DatePickerColumn>
          <DatePickerColumn>
            <DatePicker
              selectedDate={tempDateRange.end}
              onChange={(date) => handleDateRangeChange("end", date)}
            />
            <ApplyButton onClick={applyDateRange}>Применить</ApplyButton>
          </DatePickerColumn>
        </DatePickerPopup>
      )}
    </DatePickerWrapper>
  );
};

export default DateRangePicker;
