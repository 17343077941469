import React from "react";
import classes from "./loader.module.css";
function Loadering() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.loader}></div>
    </div>
  );
}

export default Loadering;
