import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styled, { keyframes } from "styled-components";
import request from "../../utils/request";

// Styled Components

const Container = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
    padding: 1.5rem;
    height: calc(100vh - 65px);
  }
`;

const Title = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #171a1f;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  max-width: 600px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: 600;
  color: #424955;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${(props) => (props.error ? "#ff0000" : "#e0e0e0")};
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${(props) => (props.error ? "#ff0000" : "#e0e0e0")};
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f8f8;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px auto;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Button = styled.button`
  background-color: #535ce8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Th = styled.th`
  background-color: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 12px;
  text-align: left;
  font-weight: 600;
  color: #565e6c;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 12px;

  @media (max-width: 768px) {
    padding: 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #f3f4f6;
  }
`;

const ActionButton = styled.button`
  padding: 5px 15px;
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  margin-right: 5px;

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
    width: 48%;
    margin-top: 10px;
  }
`;

const EditButton = styled(ActionButton)`
  background-color: #379ae6;
`;

const DeleteButton = styled(ActionButton)`
  background-color: #c02a2a;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const FilterSelect = styled(Select)`
  width: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
  margin-left: 10px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #f3f4f6;
    border-radius: 8px;
    padding: 10px;
  }
`;

// Accordion Styled Components

const AccordionItem = styled.div`
  border-bottom: 1px solid #f3f4f6;
  margin-bottom: 10px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const AccordionHeader = styled.div`
  padding: 15px 16px;
  cursor: pointer;
  background-color: #fafafb;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

const AccordionContent = styled.div`
  padding: 16px;
  display: ${(props) => (props.isOpen ? "grid" : "none")};
  gap: 10px;
  @media (min-width: 769px) {
    display: none;
  }
`;

const AccordionButton = styled.button`
  padding: 5px 15px;
  border: none;
  border-radius: 12px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
  background-color: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
  width: 48%;

  @media (min-width: 769px) {
    width: auto;
    margin-top: 0;
  }
`;

// Main Component

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state for fetching users
  const [roleFilter, setRoleFilter] = useState("");
  const [openAccordions, setOpenAccordions] = useState({});
  const refContainer = React.useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      role: "",
      telegram: "",
      phone: "",
      email: "",
      password: "",
    },
  });

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await request.get("/users"); // Fetch users from the backend API
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch users from API
  useEffect(() => {
    fetchUsers();
  }, []);

  // Submit handler for adding/updating users
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      if (editingUser) {
        // Update user API call
        const response = await request.put(`/users/${editingUser?.id}`, data);
        setUsers(
          users.map((user) =>
            user?.id === editingUser?.id ? { ...response.data } : user
          )
        );
      } else {
        // Create new user API call
        const response = await request.post("/users", data);
        setUsers([...users, response.data]); // Add new user to the list
      }
      fetchUsers();

      reset();
      setEditingUser(null);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Delete user handler
  const handleDelete = async (id) => {
    try {
      await request.delete(`/users/${id}`); // Delete user from the backend
      setUsers(users.filter((user) => user?.id !== id)); // Remove from the state
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // Edit user handler
  const handleEdit = (user) => {
     // scroll to top 
    refContainer.current.scrollIntoView({ behavior: "smooth" });
    setEditingUser(user);
    Object.keys(user).forEach((key) => {
      setValue(key, user[key]);
    });
  };

  // Toggle accordion
  const toggleAccordion = (id) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Filter users based on role
  const filteredUsers = users.filter((user) =>
    roleFilter ? user.role === roleFilter : true
  );

  return (
    <Container>
      <Title  ref={refContainer}>
        {editingUser ? "Редактировать пользователя" : "Добавить пользователя"}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Имя пользователя</Label>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Имя обязательно" }}
            render={({ field }) => (
              <Input {...field} error={errors.name} placeholder="John" />
            )}
          />
          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </FormGroup>

        <FormGroup>
          <Label>Роль</Label>
          <Controller
            name="role"
            control={control}
            rules={{ required: "Роль обязательна" }}
            render={({ field }) => (
              <Select {...field} error={errors.role}>
                <option value="">Выберите роль</option>
                <option value="manager">Обработчик</option>
                <option value="buyer">Баер</option>
                <option value="team_lead">Тим лид</option>
                <option value="owner">Овнер</option>
              </Select>
            )}
          />
          {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label>Telegram</Label>
          <Controller
            name="telegram"
            control={control}
            rules={{ required: "Telegram обязателен" }}
            render={({ field }) => (
              <Input {...field} error={errors.telegram} placeholder="@user" />
            )}
          />
          {errors.telegram && (
            <ErrorMessage>{errors.telegram.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Номер телефона</Label>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Номер телефона обязателен",
              pattern: {
                message:
                  "Неверный формат номера телефона.",
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                error={errors.phone}
                placeholder="+7 999 999-99-99"
              />
            )}
          />
          {errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label>E-mail</Label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "E-mail обязателен",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Неверный формат e-mail",
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                error={errors.email}
                placeholder="example@example.com"
              />
            )}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label>Пароль</Label>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Пароль обязателен",
              minLength: {
                value: 8,
                message: "Пароль должен содержать минимум 8 символов",
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                error={errors.password}
                placeholder="••••••••••••"
              />
            )}
          />
          {errors.password && (
            <ErrorMessage>{errors.password.message}</ErrorMessage>
          )}
        </FormGroup>
        <Button type="submit" disabled={isSubmitting}>
          {editingUser ? "Обновить" : "Добавить"}
          {isSubmitting && <Loader />}
        </Button>
      </Form>

      <Title>Смотреть пользователей</Title>
      <FilterContainer>
        <FilterSelect
          value={roleFilter}
          onChange={(e) => setRoleFilter(e.target.value)}
        >
          <option value="">Все роли</option>
          <option value="manager">Обработчик</option>
          <option value="buyer">Баер</option>
          <option value="team_lead">Тим лид</option>
          <option value="owner">Овнер</option>
        </FilterSelect>
      </FilterContainer>

      {/* Display loader while fetching users */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {/* Table for Desktop */}
          <Table>
            <thead>
              <tr>
                <Th>ФИО</Th>
                <Th>Роль</Th>
                <Th>Telegram</Th>
                <Th>Номер телефона</Th>
                <Th>E-mail</Th>
                <Th>Пароль</Th>
                <Th>Дополнительно</Th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length === 0 ? (
                <Tr>
                  <Td colSpan="7">Нет пользователей для отображения.</Td>
                </Tr>
              ) : (
                filteredUsers.map((user) => (
                  <Tr key={user?.id}>
                    <Td>{`${user?.name}`}</Td>
                    <Td>{user?.role}</Td>
                    <Td>{user?.telegram}</Td>
                    <Td>{user?.phone}</Td>
                    <Td>{user?.email}</Td>
                    <Td>{user?.view_password}</Td>
                    <Td>
                      <EditButton onClick={() => handleEdit(user)}>
                        Редактировать
                      </EditButton>
                      <DeleteButton
                        onClick={() =>
                          window.confirm("Вы уверены, что хотите удалить?") &&
                          handleDelete(user?.id)
                        }
                      >
                        Удалить
                      </DeleteButton>
                    </Td>
                  </Tr>
                ))
              )}
            </tbody>
          </Table>

          {/* Accordion for Mobile */}
          {filteredUsers.length > 0 && (
            <div>
              {filteredUsers.map((user) => (
                <AccordionItem key={user.id}>
                  <AccordionHeader onClick={() => toggleAccordion(user.id)}>
                    <span>{`${user.name} (${user.role})`}</span>
                    <span>{openAccordions[user.id] ? "-" : "+"}</span>
                  </AccordionHeader>
                  <AccordionContent isOpen={openAccordions[user.id]}>
                    <p>
                      <strong>Telegram:</strong> {user.telegram}
                    </p>
                    <p>
                      <strong>Номер телефона:</strong> {user.phone}
                    </p>
                    <p>
                      <strong>E-mail:</strong> {user.email}
                    </p>
                    <p>
                      <strong>Пароль:</strong> {user.view_password}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <AccordionButton edit onClick={() => handleEdit(user)}>
                        Редактировать
                      </AccordionButton>
                      <AccordionButton
                        onClick={() =>
                          window.confirm("Вы уверены, что хотите удалить?") &&
                          handleDelete(user.id)
                        }
                      >
                        Удалить
                      </AccordionButton>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </div>
          )}

          {/* Display message if no users found */}
          {!isLoading && filteredUsers.length === 0 && (
            <p>Нет пользователей для отображения.</p>
          )}
        </>
      )}
    </Container>
  );
};

export default UserManagement;
