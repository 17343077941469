import axios from 'axios';
export const base_url = 'https://tgcrm-api.primesox.io/api'
export const file_url = 'https://tgcrm-api.primesox.io/'
export const storage_url = 'https://tgcrm-api.primesox.io/storage/'
export const global_url = 'https://first-coders-pro.ru/'
// Create an instance of axios
const instance = axios.create({
  baseURL: base_url, // Change this to your API base URL
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

// Add request interceptor to include token in headers
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get token from localStorage or other storage method
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Attach the token if available
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle errors globally
instance.interceptors.response.use(
  (response) => response, // Simply return the response if it's successful
  (error) => {
    if (error.response) {
      // Handle 401 Unauthorized error
      if (error.response.status === 401) {
        // Optionally, trigger a logout or redirect to login page
        console.error('Unauthorized access - Redirecting to login.');
        window.location.href = '/login'; // Redirect to login page
      }

      // Handle other error responses
      console.error('Error Response:', error.response.data.message || 'An error occurred');
    } else if (error.request) {
      // Handle network errors
      console.error('Network Error:', error.message);
    }
    
    return Promise.reject(error);
  }
);

export default instance;


export const fetchData = async (page = 1) => {
  try {
    const response = await instance.get('/chat/list?chunk=' + page);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};