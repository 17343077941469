import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import AvatarSettings from "../../assets/image/AvatarSettings.png";
import { IconPlus } from "../../assets/icon";
import Modal from "./Modal";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request, { base_url, file_url } from "../../utils/request";
import axios from "axios";
import toast from "react-hot-toast";

// Styled Components

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  font-family: "Arial", sans-serif;
  overflow-y: auto;
  height: ${(props) => props.size - 70}px;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const Sidebar = styled.div`
  width: 240px;
  padding: 20px;
  border-right: 1px solid #e9ecef;

  @media (max-width: 768px) {
    display: none;
  }
`;

/* New Styled Component for Mobile Top Navigation */
const TopTabBar = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #ffffff;
    border-bottom: 1px solid #e9ecef;
    padding: 10px;
    top: 0;
    width: 100vw;
    overflow-x: auto;
  }
`;

/* Modified TabButton to be Responsive */
const TabButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: left;
  background: ${(props) => (props.active ? "#e7e9fd" : "transparent")};
  border: none;
  border-radius: 4px;
  color: ${(props) => (props.active ? "#3f51b5" : "#495057")};
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:hover {
    background: ${(props) => (props.active ? "#e7e9fd" : "#f1f3f5")};
  }

  @media (max-width: 768px) {
    width: auto;
    text-align: center;
    margin: 0 5px;
    padding: 10px 15px;
    border-radius: 0;
    flex-grow: 1;
    justify-content: center;
  }
`;

/* Adjust ContentArea to Account for Fixed TopTabBar on Mobile */
const ContentArea = styled.div`
  flex-grow: 1;
  padding: 16px 35px;

  @media (max-width: 768px) {
    padding: 16px; /* Top padding to avoid overlap with TopTabBar */
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: #212529;
  margin-bottom: 30px;
`;

const Form = styled.form`
  max-width: 500px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  color: #495057;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? "#dc3545" : "#ced4da")};
  border-radius: 4px;
  background-color: #f8f9fa;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.error ? "#dc3545" : "#80bdff")};
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.error ? "rgba(220,53,69,.25)" : "rgba(0,123,255,.25)"};
  }
`;

const ErrorMessage = styled.span`
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
  display: block;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #5a51d1;
  }

  &:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const AddPhotoButton = styled.label`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 3rem;

  &:hover {
    background-color: #5a51d1;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-left: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? "#dc3545" : "#ced4da")};
  border-radius: 4px;
  background-color: #f8f9fa;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;

  &:focus {
    outline: none;
    border-color: ${(props) => (props.error ? "#dc3545" : "#80bdff")};
    box-shadow: 0 0 0 0.2rem
      ${(props) =>
        props.error ? "rgba(220,53,69,.25)" : "rgba(0,123,255,.25)"};
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

const NotificationContainer = styled.div`
  max-width: 800px;
`;

const NotificationTitle = styled.h2`
  font-size: 24px;
  color: #212529;
  margin-bottom: 20px;
`;

const NotificationSetting = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const NotificationLabel = styled.span`
  font-size: 16px;
  color: #495057;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 62px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #6c63ff;
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #5a51d1;
  }

  &:disabled {
    background-color: #b3b3b3;
    cursor: not-allowed;
  }
`;

const Table = styled.table`
  width: 100%; /* Make table responsive */
  max-width: 1306px; /* Adjust as needed */
  margin: 29px 0 0 0px;
  border-collapse: collapse;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    width: 100%;
    overflow-x: auto;
    display: block;
  }
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;

  @media (max-width: 768px) {
    padding: 10px 8px;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  width: 146px;
  text-align: center;

  &:hover {
    background: #b8bcf6;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

/* Accordion Styled Components */
const AccordionContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
`;

const AccordionItem = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const AccordionHeader = styled.div`
  background-color: #fafafb;
  padding: 15px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
`;

const AccordionTitle = styled.span`
  font-weight: 600;
  color: #565e6c;
`;
const RowsInput = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const AccordionContent = styled.div`
  padding: 15px 12px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;
  display: ${(props) => (props.isOpen ? "grid" : "none")};
`;

const Chevron = styled.span`
  display: inline-block;
  transition: transform 0.3s ease;

  ${(props) => props.isOpen && "transform: rotate(180deg);"}
`;

// Accordion Component
const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AccordionItem>
      <AccordionHeader onClick={() => setIsOpen(!isOpen)}>
        <AccordionTitle>{title}</AccordionTitle>
        <Chevron isOpen={isOpen}>▼</Chevron>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionItem>
  );
};

// Notification Settings Component
const NotificationSettings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await request.get("/userInfo");
        setNotificationsEnabled(response.data.notify);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // setError("Failed to fetch user information");
      }
    };

    fetchUser();
  }, []);

  const handleToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await request.post("/user/notify/status", {
        notify: notificationsEnabled,
      });
      setLoading(false);
      toast.success("Настройки уведомлений сохранены");
    } catch (err) {
      setLoading(false);
      setError("Не удалось сохранить настройки уведомлений");
      toast.error("Не удалось сохранить настройки уведомлений");
    }
  };

  return (
    <NotificationContainer>
      <NotificationTitle>Настройка уведомлений</NotificationTitle>
      <NotificationSetting>
        <NotificationLabel>Включить уведомления</NotificationLabel>
        <ToggleSwitch>
          <ToggleInput
            type="checkbox"
            checked={notificationsEnabled}
            onChange={handleToggle}
          />
          <ToggleSlider />
        </ToggleSwitch>
      </NotificationSetting>
      <SaveButton onClick={handleSave} disabled={loading}>
        {loading ? "Сохранение..." : "Сохранить"}
        {loading && <Loader />}
      </SaveButton>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </NotificationContainer>
  );
};

// Telegram Settings Component with Responsive Tables/Accordions
const TelegramSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [telegram, setTelegram] = useState([]);
  const [chanels, setChanels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const fetchUser = async () => {
    try {
      const responseChange = await request.get("/chat/channel/list");
      const response = await request.get("/accounts");
      setChanels(responseChange?.data?.data);
      setTelegram(response?.data?.data);
    } catch (err) {
      // setError("Failed to fetch ");
    }
  };

  useEffect(() => {
    fetchUser();
  }, [isModalOpen]);

  const handleToggle = () => {
    setNotificationsEnabled(!notificationsEnabled);
  };

  const removeObjectById = async (id) => {
    try {
      await request.post(`/account/${id}`); // API call to delete
      fetchUser();
      toast.success("Телеграм аккаунт удален");
    } catch (error) {
      console.error("Error deleting telegram account:", error);
      toast.error("Ошибка при удалении телеграм аккаунта");
    }
  };

  const removeChanel = async (id) => {
    try {
      await request.delete(`/chat/channel/${id}`).then(() => {
        fetchUser();
        toast.success("Телеграм канал удален");
      });
    } catch (error) {
      console.error("Error deleting channel:", error);
      toast.error("Ошибка при удалении телеграм канала");
    }
  };

  async function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const contect = {};
    formData.forEach((value, key) => {
      contect[key] = value;
    });
    try {
      setIsLoading(true);
      const response = await request.post(
        `/madeline-proto/create-channel`,
        contect
      ); // API call to create channel
      setIsLoading(false);
      if (response) {
        fetchUser();
        toast.success("Канал добавлен успешно!");
      }
    } catch (error) {
      toast.error("Что-то пошло не так!");
      console.error("Error creating channel:", error);
      setIsLoading(false);
    }
    e.target.reset();
  }

  return (
    <NotificationContainer>
      <NotificationTitle>Добавить телеграм</NotificationTitle>

      <AddButton onClick={() => setIsModalOpen(true)}>
        Добавить <IconPlus />
      </AddButton>

      {/* Telegram Accounts Section */}
      <NotificationSetting>
        {isMobile ? (
          <AccordionContainer>
            {telegram.length === 0 ? (
              <p>Нет телеграм аккаунтов.</p>
            ) : (
              telegram.map((offer, index) => (
                <Accordion key={index} title={`ID: ${offer.id}`}>
                  <strong>Телефона:</strong>
                  <span
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {offer.phone_number}
                  </span>
                  <strong>APP ID:</strong>
                  <span
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {offer.api_id}
                  </span>
                  <strong>APP key:</strong>
                  <span
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {" "}
                    {offer.api_hash}
                  </span>
                  <ActionButton
                    onClick={() =>
                      window.confirm("Вы хотите удалить?") &&
                      removeObjectById(offer.id)
                    }
                  >
                    Удалить
                  </ActionButton>
                </Accordion>
              ))
            )}
          </AccordionContainer>
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Номер Телефона</Th>
                <Th>APP ID</Th>
                <Th>APP Hash key</Th>
                <Th>Действия</Th>
              </tr>
            </thead>
            <tbody>
              {telegram.map((offer, index) => (
                <Tr key={index}>
                  <Td>{offer.id}</Td>
                  <Td>{offer.phone_number}</Td>
                  <Td>{offer.api_id}</Td>
                  <Td>{offer.api_hash}</Td>
                  <Td>
                    <ActionButton
                      onClick={() =>
                        window.confirm("Вы хотите удалить?") &&
                        removeObjectById(offer.id)
                      }
                    >
                      Удалить
                    </ActionButton>
                  </Td>
                </Tr>
              ))}
              {telegram.length === 0 && (
                <Tr>
                  <Td colSpan="5" style={{ textAlign: "center" }}>
                    Нет телеграм аккаунтов.
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        )}
      </NotificationSetting>

      {/* Modal for Adding Telegram Accounts */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setTelegram={setTelegram}
      />

      <hr style={{ marginBottom: 40 }} />

      {/* Telegram Channels Section */}
      <NotificationTitle>Добавить телеграм канал</NotificationTitle>
      <form onSubmit={formSubmit}>
        <RowsInput>
          <Input type="text" name="title" placeholder="Название" />
          <Input type="text" name="about" placeholder="Описание" required />
          <Input type="text" name="username" placeholder="Username" required />
          <Input type="text" name="post" placeholder="Post" required />
          <Select name="account_id" required>
            <option value="">Account</option>
            {telegram?.map((val) => (
              <option key={val?.id} value={val?.id}>
                {val?.phone_number}
              </option>
            ))}
          </Select>
          <button
            type="submit"
            style={{
              padding: 8,
              borderRadius: 5,
              border: 0,
              background: "#cacdf8",
              color: "#535ce8",
              opacity: isLoading ? ".8" : "1",
              cursor: "pointer",
            }}
            disabled={isLoading}
          >
            {isLoading ? "Создание..." : "Создать"}
          </button>
        </RowsInput>
      </form>

      <NotificationSetting>
        {isMobile ? (
          <AccordionContainer>
            {chanels.length === 0 ? (
              <p>Нет телеграм каналов.</p>
            ) : (
              chanels.map((offer, index) => (
                <Accordion key={index} title={`ID: ${offer.id}`}>
                  <strong>Название:</strong> {offer.title}
                  <strong>Подписчиков:</strong>{" "}
                  {offer.participants_count}
                  <strong>Username:</strong>{" "}
                  <a
                    href={`https://t.me/${offer.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://t.me/{offer.username}
                  </a>
                  <ActionButton
                    onClick={() =>
                      window.confirm("Вы хотите удалить?") &&
                      removeChanel(offer.id)
                    }
                  >
                    Удалить
                  </ActionButton>
                </Accordion>
              ))
            )}
          </AccordionContainer>
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Название</Th>
                <Th>Количество подписчиков</Th>
                <Th>Username</Th>
                <Th>Действия</Th>
              </tr>
            </thead>
            <tbody>
              {chanels.map((offer, index) => (
                <Tr key={index}>
                  <Td>#{offer.id}</Td>
                  <Td>{offer.title}</Td>
                  <Td>{offer.participants_count}</Td>
                  <Td>
                    <a
                      href={`https://t.me/${offer.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://t.me/{offer.username}
                    </a>
                  </Td>
                  <Td>
                    <ActionButton
                      onClick={() =>
                        window.confirm("Вы хотите удалить?") &&
                        removeChanel(offer.id)
                      }
                    >
                      Удалить
                    </ActionButton>
                  </Td>
                </Tr>
              ))}
              {chanels.length === 0 && (
                <Tr>
                  <Td colSpan="5" style={{ textAlign: "center" }}>
                    Нет телеграм каналов.
                  </Td>
                </Tr>
              )}
            </tbody>
          </Table>
        )}
      </NotificationSetting>
    </NotificationContainer>
  );
};

// Settings Component
const Settings = () => {
  const { user } = SelectAuth();
  const { width, height } = useWindowSize();
  const isMobile = width <= 768;
  const [activeTab, setActiveTab] = useState("profile");
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [avatarSrc, setAvatarSrc] = useState(
    "https://cdn-icons-png.flaticon.com/256/149/149071.png"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await request.get("/profile/info");
        setInfo(response.data);
        if (response?.data?.image !== null) {
          setAvatarSrc(file_url + response?.data?.image);
        }
        // setNotifications(response.data); // Assuming API returns notifications in response.data
      } catch (err) {
        // setError("Failed to fetch ");
      }
    };

    fetchUser();
  }, []);

  // Function to handle saving profile data
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token"); // Assuming you're storing the token
      const formData = new FormData();

      // Add form data
      formData.append("name", data.name);
      formData.append("telegram", data.telegram);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("password", data.password);
      // Add avatar if it was changed
      if (data.image && data.image.length > 0) {
        formData.append("image", data.image[0]);
      }

      // Send API request to update the profile
      const response = await axios.post(base_url + "/profile/edit", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Профиль успешно обновлен");
    } catch (error) {
      toast.error("Ошибка при обновлении профиля");
      console.error("Error updating profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setAvatarSrc(imageUrl);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return (
          <>
            <Title>Профиль</Title>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <AvatarContainer>
                  <Avatar src={avatarSrc} alt="Аватар пользователя" />
                  <AddPhotoButton htmlFor="photo-upload">
                    Добавить фото
                  </AddPhotoButton>
                  <HiddenFileInput
                    id="photo-upload"
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    name="image"
                    {...register("image")}
                  />
                </AvatarContainer>
              </FormGroup>
              <FormGroup>
                <Label>Имя</Label>
                <Input
                  {...register("name", {
                    required: "Это поле обязательно",
                  })}
                  defaultValue={info?.name || ""}
                  error={errors.name}
                />
                {errors.name && (
                  <ErrorMessage>{errors.name.message}</ErrorMessage>
                )}
              </FormGroup>
              {/* Uncomment and customize if needed
              <FormGroup>
                <Label>Фамилия</Label>
                <Input
                  {...register("lastName", {
                    required: "Это поле обязательно",
                  })}
                  defaultValue="Hill"
                  error={errors.lastName}
                />
                {errors.lastName && (
                  <ErrorMessage>{errors.lastName.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Отчество</Label>
                <Input {...register("middleName")} defaultValue="Lorem" />
              </FormGroup>
              */}
              <FormGroup>
                <Label>Telegram</Label>
                <Input
                  {...register("telegram")}
                  defaultValue={info?.telegram || ""}
                />
              </FormGroup>
              <FormGroup>
                <Label>Номер телефона</Label>
                <Input
                  {...register("phone", {
                    required: "Это поле обязательно",
                    pattern: {
                      value: /^\+?[0-9\s-()]{10,20}$/,
                      message: "Некорректный номер телефона",
                    },
                  })}
                  defaultValue={info?.phone || ""}
                  error={errors.phone}
                />
                {errors.phone && (
                  <ErrorMessage>{errors.phone.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>E-mail</Label>
                <Input
                  {...register("email", {
                    required: "Это поле обязательно",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Некорректный email адрес",
                    },
                  })}
                  defaultValue={info?.email || ""}
                  error={errors.email}
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </FormGroup>
              <FormGroup>
                <Label>Пароль</Label>
                <Input
                  type="password"
                  {...register("password", {
                    required: "Это поле обязательно",
                    minLength: {
                      value: 8,
                      message: "Пароль должен содержать минимум 8 символов",
                    },
                  })}
                  placeholder="••••••••••••"
                  error={errors.password}
                />
                {errors.password && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </FormGroup>
              {/* Uncomment and customize if needed
              <FormGroup>
                <Label>Ваша роль</Label>
                <Select
                  {...register("role", { required: "Выберите вашу роль" })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Выберите роль
                  </option>
                  <option value="owner">Обработчик</option>
                  <option value="manager">Баер</option>
                  <option value="buyer">Тим лид</option>
                  <option value="team_lead">Овнер</option>
                </Select>
                {errors.role && (
                  <ErrorMessage>{errors.role.message}</ErrorMessage>
                )}
              </FormGroup>
              */}
              <SubmitButton type="submit" disabled={isLoading}>
                Сохранить
                {isLoading && <Loader />}
              </SubmitButton>
            </Form>
          </>
        );
      case "notifications":
        return <NotificationSettings />;
      case "telegram":
        return <TelegramSettings />;
      default:
        return null;
    }
  };

  return (
    <MainContainer size={height}>
      {/* Render Sidebar on Desktop */}
      {!isMobile && (
        <Sidebar>
          <TabButton
            active={activeTab === "notifications"}
            onClick={() => setActiveTab("notifications")}
          >
            Уведомления
          </TabButton>
          <TabButton
            active={activeTab === "profile"}
            onClick={() => setActiveTab("profile")}
          >
            Профиль
          </TabButton>
          {["owner"].includes(user?.role) && (
            <TabButton
              active={activeTab === "telegram"}
              onClick={() => setActiveTab("telegram")}
            >
              Добавить телеграм аккаунт
            </TabButton>
          )}
        </Sidebar>
      )}

      {/* Render TopTabBar on Mobile */}
      {isMobile && (
        <TopTabBar>
          <TabButton
            active={activeTab === "notifications"}
            onClick={() => setActiveTab("notifications")}
          >
            Уведомления
          </TabButton>
          <TabButton
            active={activeTab === "profile"}
            onClick={() => setActiveTab("profile")}
          >
            Профиль
          </TabButton>
          {["owner"].includes(user?.role) && (
            <TabButton
              active={activeTab === "telegram"}
              onClick={() => setActiveTab("telegram")}
            >
              Добавить телеграм аккаунт
            </TabButton>
          )}
        </TopTabBar>
      )}

      {/* Content Area */}
      <ContentArea>{renderContent()}</ContentArea>
    </MainContainer>
  );
};

export default Settings;
