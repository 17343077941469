import React, { useState } from "react";
import styled from "styled-components";
import { ChevronDown, X } from "lucide-react";

const ModalWrapper = styled.div`
  font-family: Arial, sans-serif;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  position: absolute;
  z-index: 1000;
  right: 3rem;
  top: 10rem;
`;

const SelectGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Select = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const OptionsList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const Option = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SearchButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #cacdf8;
  color: #535ce8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #b8bcf6;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;

const SelectFilter = ({ isOpen, onClose, onSearch }) => {
  const [category, setCategory] = useState("");
  const [master, setMaster] = useState("");
  const [archiveStatus, setArchiveStatus] = useState("Скрыть архивные");
  const [openSelect, setOpenSelect] = useState(null);

  const handleSearch = () => {
    onSearch({ category, master, archiveStatus });
    onClose();
  };

  const handleSelectClick = (selectName) => {
    setOpenSelect(openSelect === selectName ? null : selectName);
  };

  const handleOptionSelect = (option, setter) => {
    setter(option);
    setOpenSelect(null);
  };

  const renderOptions = (options, currentValue, setter) => {
    return (
      <OptionsList>
        {options.map((option) => (
          <Option
            key={option}
            onClick={() => handleOptionSelect(option, setter)}
          >
            {option}
          </Option>
        ))}
      </OptionsList>
    );
  };

  if (!isOpen) return null;

  return (
    <ModalWrapper>
      <CloseButton onClick={onClose}>
        <X size={20} />
      </CloseButton>

      <SelectGroup>
        <Label>Категория</Label>
        <Select onClick={() => handleSelectClick("category")}>
          <span>{category || "Выберите категорию"}</span>
          <ChevronDown size={20} />
        </Select>
        {openSelect === "category" &&
          renderOptions(
            ["Категория 1", "Категория 2", "Категория 3"],
            category,
            setCategory
          )}
      </SelectGroup>

      <SelectGroup>
        <Label>Мастер</Label>
        <Select onClick={() => handleSelectClick("master")}>
          <span>{master || "Выберите мастера"}</span>
          <ChevronDown size={20} />
        </Select>
        {openSelect === "master" &&
          renderOptions(
            ["Мастер 1", "Мастер 2", "Мастер 3"],
            master,
            setMaster
          )}
      </SelectGroup>

      <SelectGroup>
        <Label>Статус архивации</Label>
        <Select onClick={() => handleSelectClick("archiveStatus")}>
          <span>{archiveStatus}</span>
          <ChevronDown size={20} />
        </Select>
        {openSelect === "archiveStatus" &&
          renderOptions(
            ["Скрыть архивные", "Показать все"],
            archiveStatus,
            setArchiveStatus
          )}
      </SelectGroup>

      <SearchButton onClick={handleSearch}>Поиск</SearchButton>
    </ModalWrapper>
  );
};

export default SelectFilter;
