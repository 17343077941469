import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Pencil, ChevronDown, Plus } from "lucide-react";
import ModalKanbanEdit from "./ModalKanban";
import { useWindowSize } from "../../utils";
import request, { base_url } from "../../utils/request";
import axios from "axios";
import toast from "react-hot-toast";
const MainContainer = styled.div`
  position: relative;
  // min-height: 100vh;
  width: 100%;
  background: #fafafb;
  box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12);
  overflow-y: auto;
  height: ${(props) => props.size - 50}px;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const FilterButton = styled.div`
  margin: 33px 0 0 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 49px;
  @media (max-width: 768px) {
    padding: 0px 10px;
    flex-direction: row-reverse;
  }
`;

const FilterText = styled.span`
  color: #535ce8;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const ColumnsContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  gap: 20px;
  padding: 32px 45px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
const ColumnsWrapper = styled.div`
  width: 100%;
  overflow: auto;
  @media (max-width: 768px) {
    width: 95vw;
  }
`;
const Column = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  background: #edf1f5a6;
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 16px 8px;
  position: sticky;
  top: 0;
  background: #edf1f5;
  z-index: 1;
  transition: all 0.3s ease;
`;

const ColumnIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

const ColumnTitle = styled.span`
  margin-left: 10px;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
`;

const Card = styled.div`
  position: relative;
  width: calc(100% - 32px);
  margin: 16px;
  background: #ffffff;
  border-radius: 3.506px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  ${(props) =>
    props.isDragging &&
    `
    border: 3px dotted ${props.color};
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    opacity: 0.8;
  `}
`;

const CardStripe = styled.div`
  position: absolute;
  width: 7px;
  top: 0;
  bottom: 0;
  left: 0;
`;

const CardTitle = styled.span`
  position: absolute;
  top: 14px;
  left: 21px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardPriority = styled.span`
  position: absolute;
  top: 17px;
  right: 14px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const CardInfo = styled.div`
  position: relative;
  padding: 45px 21px 0;
`;

const CardInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const CardInfoDate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CardInfoLabel = styled.span`
  color: #565e6c;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
`;

const CardInfoValue = styled.span`
  color: #565e6c;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
`;

const CardDivider = styled.div`
  margin: 20px 0;
  height: 1px;
  background: #e0e0e0;
`;

const CardFooter = styled.div`
  padding: 0 21px 14px;
`;

const CardFooterItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const CardFooterLabel = styled.span`
  color: #379ae6;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
`;

const CardFooterValue = styled.span`
  color: #565e6c;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: #535ce8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  &:hover {
    background: #b8bcf6;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 7px 14px;
    font-weight: 500;
  }
`;

const FilterWraper = styled.div`
  background: #f1f2fd;
  border-radius: 4px;
  width: 86px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

const KanbanBoard = () => {
  const { height } = useWindowSize();
  const [isKanbanOpen, setIsKanbanOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initial, setInitial] = useState({});

  // Mapping column statuses to Russian statuses
  const [columns, setColumns] = useState([
    { id: "todo", title: "делать", color: "#379ae6", status: "Делать" },
    {
      id: "inProgress",
      title: "в ходе выполнения",
      color: "#efb034",
      status: "В ходе выполнения",
    },
    { id: "done", title: "Сделанный", color: "#1dd75b", status: "Сделанный" },
    {
      id: "issue",
      title: "Не выполненно",
      color: "#f13232",
      status: "Не выполненно",
    },
  ]);

  const [cards, setCards] = useState([]);
  const columnRefs = useRef([]);

  // Fetch tasks dynamically from the API
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await request.get("/tasks", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const tasks = response.data;
        setCards(tasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [isKanbanOpen]);

  // Handle drag-and-drop event
  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceColumn = columns.find((col) => col.id === source.droppableId);
    const destColumn = columns.find(
      (col) => col.id === destination.droppableId
    );

    const sourceCards = cards.filter(
      (card) => card.status === sourceColumn.status
    );
    const destCards = cards.filter((card) => card.status === destColumn.status);

    const [removed] = sourceCards.splice(source.index, 1);
    destCards.splice(destination.index, 0, removed);

    const updatedCard = {
      ...removed,
      status: destColumn.status, // Updating the status to the new column's status
      color: destColumn.color,
    };

    // Optimistic UI update
    const updatedCards = cards.map((card) =>
      card.id === updatedCard.id ? updatedCard : card
    );
    setCards(updatedCards);

    try {
      // Make an API call to update the card status via PUT or POST request
      await axios.post(
        base_url + `/taskUpdate/${updatedCard.id}`,
        {
          status: updatedCard.status, // Send the updated status
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Re-use token
          },
        }
      );

      toast.success("Task status updated successfully");
    } catch (error) {
      toast.error("Error updating task status:", error);
    }
  };
  const openKanban = (initData) => {
    setIsEdit(true);
    setInitial(initData);
    setIsKanbanOpen(true);
  };
  const closeKanban = () => {
    setInitial({});
    setIsEdit(false);
    setIsKanbanOpen(false);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ModalKanbanEdit
        isEdit={isEdit}
        initialData={initial}
        isOpen={isKanbanOpen}
        onClose={closeKanban}
      />
      <MainContainer size={height}>
        <FilterButton>
          <FilterWraper>
            <FilterText>Все</FilterText>
            <ChevronDown size={16} />
          </FilterWraper>
          <div>
            <SubmitButton type="button" onClick={() => setIsKanbanOpen(true)}>
              <div>Создать задачу</div> <Plus size={16} />
            </SubmitButton>
          </div>
        </FilterButton>
        <ColumnsWrapper>
          <ColumnsContainer>
            {columns.map((column, columnIndex) => (
              <Droppable key={column.id} droppableId={column.id}>
                {(provided) => (
                  <Column
                    {...provided.droppableProps}
                    ref={(el) => {
                      provided.innerRef(el);
                      columnRefs.current[columnIndex] = el;
                    }}
                  >
                    <ColumnHeader data-column-header>
                      <ColumnIcon style={{ background: column.color }}>
                        {
                          cards.filter((card) => card.status === column.status)
                            .length
                        }
                      </ColumnIcon>
                      <ColumnTitle style={{ color: column.color }}>
                        {column.title}
                      </ColumnTitle>
                    </ColumnHeader>
                    {cards
                      .filter((card) => card.status === column.status)
                      .map((card, index) => (
                        <Draggable
                          key={card.id}
                          draggableId={String(card.id)} // Ensure draggableId is a string
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              color={card.color || column.color}
                            >
                              <CardStripe
                                style={{
                                  background: card.color || column.color,
                                }}
                              />
                              <CardTitle
                                style={{ color: card.color || column.color }}
                              >
                                {card.title}
                              </CardTitle>
                              <CardPriority
                                style={{ color: card.color || column.color }}
                              >
                                {card.priority}
                              </CardPriority>
                              <CardInfo>
                                <CardInfoItem>
                                  <CardInfoLabel>Площадка</CardInfoLabel>
                                  <CardInfoValue>{card.platform}</CardInfoValue>
                                </CardInfoItem>
                                <CardInfoItem>
                                  <CardInfoLabel>Макс. расход</CardInfoLabel>
                                  <CardInfoValue>
                                    {card.max_expense}
                                  </CardInfoValue>
                                </CardInfoItem>
                                <CardInfoItem>
                                  <CardInfoLabel>Доход</CardInfoLabel>
                                  <CardInfoValue>
                                    {card.expected_income}
                                  </CardInfoValue>
                                </CardInfoItem>
                                <CardInfoDate>
                                  <CardInfoLabel>
                                    {card.start_date}
                                  </CardInfoLabel>
                                  <CardInfoValue
                                    onClick={() => openKanban(card)}
                                  >
                                    <Pencil size={14} cursor="pointer" />
                                  </CardInfoValue>
                                </CardInfoDate>
                              </CardInfo>
                              <CardDivider />
                              <CardFooter>
                                <CardFooterItem>
                                  <CardFooterLabel>Назначил</CardFooterLabel>
                                  <CardFooterValue>
                                    {card.responsible_person}
                                  </CardFooterValue>
                                </CardFooterItem>
                                <CardFooterItem>
                                  <CardFooterLabel>
                                    Ответственное лицо
                                  </CardFooterLabel>
                                  <CardFooterValue>
                                    {card.responsible_person}
                                  </CardFooterValue>
                                </CardFooterItem>
                              </CardFooter>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Column>
                )}
              </Droppable>
            ))}
          </ColumnsContainer>
        </ColumnsWrapper>
      </MainContainer>
    </DragDropContext>
  );
};

export default KanbanBoard;
