import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LogOut, Menu, X } from "lucide-react"; // Added X icon for closing
import { sidebarItems } from "./sidebarItem";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";

// Styled Components

const SidebarWrapper = styled.aside`
  min-width: 4.5rem;
  max-height: ${(props) => props.size + 90}px;
  height: calc(100vh - 64px);
  /* overflow: auto; */
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  display: flex;
  flex-direction: column;
  border-right: 1.5px solid #f8f9fa;
  position: relative; /* Added for positioning context */
  overflow-y: auto; /* Added for scrollable content */

  @media (max-width: 768px) {
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    width: 70%;
    max-width: 300px;
    transform: ${(props) =>
      props.isOpen ? "translateX(0)" : "translateX(-100%)"};
  }
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  flex-grow: 1;
  margin-bottom: 10rem;
  position: relative; /* Ensure positioning context for tooltips */
  z-index: 1; /* Ensure it is above the background */

  @media (max-width: 768px) {
    margin-bottom: 5rem;
    padding-top: 55px;
  }
`;

// New Scrollable Container
const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
`;

// Rest of the styled components remain the same...

const SidebarItemStyled = styled(Link)`
  width: 56px;
  height: 56px;
  margin-bottom: 5px;
  background: ${(props) => (props.active ? "#f3f4f6" : "transparent")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  color: inherit;
  position: relative;

  &:hover {
    background: #f3f4f6;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 50px;
    justify-content: flex-start;
    padding-left: 20px;
    border-radius: 0;
  }
`;

// Updated Tooltip with dynamic positioning
const Tooltip = styled.div`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left + 10}px;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  z-index: 1001; /* Ensure tooltip is above other elements */
  pointer-events: none; /* Prevent tooltip from capturing mouse events */

  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoutItem = styled(SidebarItemStyled)`
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const Hamburger = styled.button`
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1100;

  @media (max-width: 768px) {
    display: block;
  }

  svg {
    width: 32px;
    height: 32px;
    color: #000;
  }
`;

const Overlay = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
  }
`;

// SidebarItem Component
function SidebarItem({ icon: Icon, path, title, onClick }) {
  const location = useLocation();
  const isActive = location.pathname === path;
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Handle mouse movement to update tooltip position
  const handleMouseMove = (e) => {
    // Add some offset so the tooltip doesn't overlap the cursor
    const offsetX = 10;
    const offsetY = 10;
    setTooltipPosition({ x: e.clientX + offsetX, y: e.clientY + offsetY });
  };

  return (
    <SidebarItemStyled
      to={path}
      active={isActive}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onMouseMove={handleMouseMove}
      onClick={onClick}
    >
      <Icon size={32} color={isActive ? "#000000" : "#6E7787"} />
      <Tooltip
        show={showTooltip}
        top={tooltipPosition.y}
        left={tooltipPosition.x}
      >
        {title}
      </Tooltip>
      {/* Show title on mobile */}
      <MobileTitle>{title}</MobileTitle>
    </SidebarItemStyled>
  );
}

// Styled component for showing titles on mobile
const MobileTitle = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: inline;
    margin-left: 10px;
    font-size: 16px;
    color: #000;
  }
`;

// Main Sidebar Component
function Sidebar() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [showLogoutTooltip, setShowLogoutTooltip] = useState(false);
  const [logoutTooltipPosition, setLogoutTooltipPosition] = useState({
    x: 0,
    y: 0,
  });
  const { user } = SelectAuth();

  const roleSidebarItems = {
    owner: sidebarItems,
    manager: sidebarItems.filter((item) =>
      ["", "messages", "analytics", "documents", "settings"].includes(
        item.path.slice(1)
      )
    ),
    buyer: sidebarItems.filter((item) =>
      ["", "analytics", "documents","settings"].includes(
        item.path.slice(1)
      )
    ),
    team_lead: sidebarItems.filter((item) =>
      [
        "",
        "analytics",
        "documents",
        "consumables/warehouse",
        "command",
        "settings",
      ].includes(item.path.slice(1))
    ),
  };

  const SidebarItems = roleSidebarItems[user.role] || [];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Close sidebar on navigation (for mobile)
  const handleNavigation = (path) => {
    navigate(path);
    if (width <= 768) {
      setIsOpen(false);
    }
  };

  // Handle mouse movement for logout tooltip
  const handleLogoutMouseMove = (e) => {
    const offsetX = 10;
    const offsetY = 10;
    setLogoutTooltipPosition({
      x: e.clientX + offsetX,
      y: e.clientY + offsetY,
    });
  };

  return (
    <>
      {/* Hamburger Menu */}
      <Hamburger onClick={toggleSidebar} aria-label="Toggle Sidebar">
        {isOpen ? <X /> : <Menu />}
      </Hamburger>

      {/* Overlay */}
      <Overlay isOpen={isOpen} onClick={toggleSidebar} />

      {/* Sidebar */}
      <SidebarWrapper size={height} isOpen={isOpen}>
        <SidebarNav>
          {/* Scrollable Content */}
          <SidebarContent>
            {SidebarItems.map((item, index) => (
              <SidebarItem
                key={index}
                icon={item.icon}
                path={item.path}
                title={item.title}
                onClick={() => handleNavigation(item.path)}
              />
            ))}

            {/* Logout Item */}
            <LogoutItem
              as="div"
              onMouseEnter={() => setShowLogoutTooltip(true)}
              onMouseLeave={() => setShowLogoutTooltip(false)}
              onMouseMove={handleLogoutMouseMove}
              onClick={() => handleNavigation("/login")}
            >
              <LogOut size={32} color="#6E7787" />
              <Tooltip
                show={showLogoutTooltip}
                top={logoutTooltipPosition.y}
                left={logoutTooltipPosition.x}
              >
                Выйти
              </Tooltip>
              <MobileTitle>Выйти</MobileTitle>
            </LogoutItem>
          </SidebarContent>
        </SidebarNav>
      </SidebarWrapper>
    </>
  );
}

export default Sidebar;
