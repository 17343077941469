import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { X } from "lucide-react";
import request from "../../utils/request";
import toast from "react-hot-toast";
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 26, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContainer = styled.div`
  width: 560px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  @media (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    border-radius: 0px;
    padding: 4px;
  }
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
`;

const ModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin: 0;
`;

const CloseButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;
  background-color: white;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #dee1e6;
  margin: 0;
`;

const FormContent = styled.div`
  padding: 24px;
  overflow-y: scroll;
  max-height: 600px;
  scrollbar-width: none;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const inputStyles = css`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #4a7dff;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const Select = styled.select`
  ${inputStyles}
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const SMSInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SMSInput = styled.input`
  width: 64px;
  height: 55px;
  text-align: center;
  font-size: 29px;
  border: 1px solid #d1d5db;
  border-radius: 6px;

  &:focus {
    outline: none;
    border-color: #4a7dff;
  }
`;

const Timer = styled.div`
  font-size: 14px;
  color: #6b7280;
  margin-top: 8px;
`;

const Modal = ({ onClose, isOpen, setTelegram }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [showSMSConfirmation, setShowSMSConfirmation] = useState(false);
  const [timer, setTimer] = useState(59);
  const [contact, setContact] = useState({});
  const {
    control: initialFormControl,
    handleSubmit: handleInitialSubmit,
    formState: { errors: initialFormErrors },
  } = useForm({});

  const { control: smsFormControl, handleSubmit: handleSMSSubmit } = useForm({
    defaultValues: {
      digit1: "",
      digit2: "",
      digit3: "",
      digit4: "",
      digit5: "",
    },
  });
  const { control: secondControl, handleSubmit: secondSubmit } = useForm({
    defaultValues: {
      password: "",
    },
  });
  useEffect(() => {
    let interval;
    if (showSMSConfirmation && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showSMSConfirmation, timer]);

  // Function to authenticate user with Telegram using Axios
  const authenticateTelegram = async (data) => {
    try {
      setContact({
        phone_number: data.phone,
        api_id: data.api_id,
        api_hash: data.api_hash,
      });
      const response = await request.post("/madeline-proto/auth", {
        phone_number: data.phone,
        api_id: data.api_id,
        api_hash: data.api_hash,
      });

      console.log("Auth response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error during Telegram authentication:", error);
    }
  };

  const onInitialSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      // Call the authenticateTelegram function
      const authResponse = await authenticateTelegram(data);

      if (authResponse.status) {
        // You can now handle the response from Telegram (e.g., setting user token)
        setTelegram((prev) => [...prev, authResponse]);

        // Proceed to SMS confirmation after successful authentication
      } else {
        toast.error("Something is error please try again");
      }
      setShowSMSConfirmation(true);
    } catch (error) {
      console.error("Authentication failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSMSSubmit = async (data) => {
    setIsSubmitting(true);
    console.log("SMS Code:", Object.values(data).join(""));
    try {
      setContact({
        phone_code: Object.values(data).join(""),
        ...contact,
      });
      const response = await request.post("/madeline-proto/auth", {
        phone_code: Object.values(data).join(""),
        ...contact,
      });
      console.log("Auth response:", response.data);
      if (response.data.cache === "password") {
        setIsPassword(true);
      } else {
        onClose();
      }
      setIsSubmitting(false);
      return response.data;
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error during Telegram authentication:", error);
      onClose();
      throw new Error("Failed to authenticate with Telegram");
    }
  };

  const secondPasswordSubmit = async (data) => {
    try {
      const response = await request.post("/madeline-proto/auth", {
        password: data?.password,
        ...contact,
      });
      console.log("Auth response:", response.data);
      onClose();
      return response.data;
    } catch (error) {
      console.error("Error during Telegram authentication:", error);
      onClose();
      throw new Error("Failed to authenticate with Telegram");
    }
  };

  const renderInitialForm = () => (
    <form onSubmit={handleInitialSubmit(onInitialSubmit)}>
      <FormField>
        <Label>Номер телефона</Label>
        <Controller
          name="phone"
          control={initialFormControl}
          rules={{
            required: "Это поле обязательно",
            pattern: {
              value: /^\+\d+$/,
              message: "Номер телефона должен содержать только '+' и цифры",
            },
          }}
          render={({ field }) => <Input {...field} />}
        />
        {initialFormErrors.phone && (
          <ErrorMessage>{initialFormErrors.phone.message}</ErrorMessage>
        )}
      </FormField>

      <FormField>
        <Label>APP ID</Label>
        <Controller
          name="api_id"
          control={initialFormControl}
          rules={{
            required: "Это поле обязательно",
            pattern: {
              value: /^\d+$/,
              message: "APP ID должен содержать только цифры",
            },
          }}
          render={({ field }) => <Input {...field} />}
        />
        {initialFormErrors.api_id && (
          <ErrorMessage>{initialFormErrors.api_id.message}</ErrorMessage>
        )}
      </FormField>

      <FormField>
        <Label>APP Hash key</Label>
        <Controller
          name="api_hash"
          control={initialFormControl}
          rules={{
            required: "Это поле обязательно",
            minLength: {
              value: 32,
              message: "APP Hash должен быть не менее 32 символов",
            },
            pattern: {
              value: /^[A-Za-z0-9]+$/,
              message: "APP Hash должен содержать только буквы и цифры",
            },
          }}
          render={({ field }) => <Input {...field} />}
        />
        {initialFormErrors.api_hash && (
          <ErrorMessage>{initialFormErrors.api_hash.message}</ErrorMessage>
        )}
      </FormField>

      <SubmitButton type="submit" disabled={isSubmitting}>
        {isSubmitting
          ? "Загрузка..."
          : showSMSConfirmation
          ? "Отправить"
          : "Продолжить"}
      </SubmitButton>
    </form>
  );

  const renderSMSConfirmation = () => (
    <form onSubmit={handleSMSSubmit(onSMSSubmit)}>
      <Label>Введите СМС-код</Label>
      <SMSInputContainer>
        {[1, 2, 3, 4, 5].map((digit) => (
          <Controller
            key={`digit${digit}`}
            name={`digit${digit}`}
            control={smsFormControl}
            rules={{
              required: true,
              pattern: /^[0-9]$/,
            }}
            render={({ field }) => (
              <SMSInput
                {...field}
                maxLength={1}
                onKeyUp={(e) => {
                  if (e.target.value && digit < 5) {
                    e.target.nextSibling.focus();
                  }
                }}
              />
            )}
          />
        ))}
      </SMSInputContainer>
      <Timer>01:{timer.toString().padStart(2, "0")}</Timer>
      <SubmitButton type="submit" disabled={isSubmitting}>
        {isSubmitting
          ? "Загрузка..."
          : showSMSConfirmation
          ? "Отправить"
          : "Продолжить"}
      </SubmitButton>
    </form>
  );

  const renderSecondPassword = () => (
    <form onSubmit={secondSubmit(secondPasswordSubmit)}>
      <FormField>
        <Label>Пароль</Label>
        <Controller
          name="password"
          control={secondControl}
          rules={{ required: "Это поле обязательно" }}
          render={({ field }) => <Input {...field} />}
        />
        {secondControl.password && (
          <ErrorMessage>{secondControl.password.message}</ErrorMessage>
        )}
      </FormField>
      <SubmitButton type="submit" disabled={isSubmitting}>
        {isSubmitting
          ? "Загрузка..."
          : showSMSConfirmation
          ? "Отправить"
          : "Продолжить"}
      </SubmitButton>
    </form>
  );
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>
            {showSMSConfirmation ? "Подтверждение" : "Войдите в свой Telegram"}
          </ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>
        </ModalHeader>
        <HorizontalLine />
        <FormContent>
          {showSMSConfirmation && !isPassword && renderSMSConfirmation()}
          {!isPassword && !showSMSConfirmation && renderInitialForm()}
          {isPassword && renderSecondPassword()}
        </FormContent>
        <HorizontalLine />
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
