import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { ForwardIcon, Image, LoaderIcon, Paperclip } from "lucide-react";
import IconButton from "../IconButton";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request, {
  base_url,
  fetchData,
  file_url,
  storage_url,
} from "../../utils/request";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loadering from "../Loader/Loadering";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import toast from "react-hot-toast";
import { OggRecorder } from "../../assets/webpToOgg/OggRecorder";
import { NavLink } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import { SavedMessageIcon } from "../../assets/icon";

const ChatContainer = styled.section`
  width: 100%;
  height: ${(props) => props.size - 80}px;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatHeaderAvatar = styled.div`
  width: 64px;
  height: 64px;
  background: #f3f4f6;
  border-radius: 32px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatHeaderName = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #171a1f;
  margin: 0;
`;

const ChatBody = styled.div`
  flex-grow: 1;
  background: #f8f9fa;
  overflow-y: auto;
  padding: 20px;
  max-height: 72vh;
  scrollbar-width: thin;
  scrollbar-color: #f3f4f6 #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  position: relative;
  gap: 10px;
`;

const MessageContent = styled.div`
  background: ${(props) => (props.isUser ? "#f1f2fd" : "#ffffff")};
  border: 1px solid ${(props) => (props.isUser ? "#cacdf8" : "#f3f4f6")};
  border-radius: 4px;
  padding: 11px;
  max-width: 70%;
  min-width: 100px;
  position: relative;
`;

const MessageText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #171a1f;
  margin: 0;
`;

const MessageTime = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #6e7787;
  margin-top: 4px;
  display: block;
`;

const ChatInputArea = styled.div`
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  height: 35px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  padding: 0 12px;
  margin: 0 12px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
`;

const SendButton = styled(IconButton)`
  height: 34px;
  background: #535ce8;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 16px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #3e47cc;
  }
`;

const AvatarIsUser = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 9px;
`;
const rotate = keyframes`
  to {
    transform: rotate(0.5turn);
  }
`;

const LoadIcon = styled.div`
  animation: ${rotate} 1s infinite;
`;

const ForwardMessageContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  background: #f1f2fd;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(23, 26, 31, 0.1);
  margin-bottom: 20px;
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0.8);

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(23, 26, 31, 0.1);
  }

  ${MessageContainer}:hover & {
    opacity: 1;
    transform: scale(1);
  }

  ${(props) =>
    props.isUser ? "right: calc(100% + 10px);" : "left: calc(100% + 10px);"}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationCard = styled.div`
  width: 364px;
  height: 123px;
  margin: 16px 0;
  background: ${(props) => (props.isSelected ? "#F3F4F6" : "#ffffff")};
  border: 0.1px solid ${(props) => (props.isSelected ? "#65d46a78" : "#ffffff")};
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  opacity: 0;
  animation: ${fadeIn} 400ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NotificationContent = styled.div`
  padding: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => props.color || "#f3f4f6"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f494a;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

const UserName = styled.span`
  color: #323842;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
`;

const ReplyText = styled.span`
  color: #6e7787;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const TimeStamp = styled.span`
  color: #9095a0;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;

const MessagePreview = styled.div`
  color: #171a1f;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;

const EmailPreview = styled.div`
  color: #323842;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2acccf;
  margin-left: 8px;
`;

function Dialogue() {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const { id } = useParams();
  const location = useLocation();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get("account_id");

  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chunk, setChunk] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [isAtBottom, setIsAtBottom] = useState(true);
  const fileInputRef = useRef(null);
  const textInputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const myId = Number(localStorage["id"]);
  const [isFullScreen, setIsFullScreen] = useState(null);

  const scrollChatToBottom = () => {
    setTimeout(() => {
      chatBodyRef.current?.scrollTo({
        top: (chatBodyRef.current.scrollHeight || 100000000) + 1000000000,
        behavior: "smooth",
      });
    }, 1000);
  };

  useEffect(() => {
    const fetchDatas = async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };
    fetchDatas();
  }, [id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await request.get("/chat/info/" + id);
        setInfo(response?.data?.data[0]);
      } catch (err) {
        // Handle error
      }
    };
    fetchUser();
  }, [id]);

  const fetchMessages = async (chunkNumber = 1, initialLoad = false) => {
    if (!info?.id) return;

    try {
      const response = await request.get(
        `/chat/messages?chunk=${chunkNumber}&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();

      scrollChatToBottom();

      if (newMessages && newMessages.length > 0) {
        if (initialLoad) {
          setMessages(newMessages);
          setChunk(1);
          setHasMoreMessages(true);
        } else {
          setMessages((prevMessages) => [...newMessages, ...prevMessages]);
        }
      } else {
        setHasMoreMessages(false);
      }
    } catch (err) {
      // Handle error
    }
  };
  useEffect(() => {
    // Fetch initial messages
    if (chatBodyRef.current) fetchMessages(1, true);
  }, [info, chatBodyRef.current]);
  const handleScroll = () => {
    if (chatBodyRef.current) {
      const {
        scrollTop = 0,
        scrollHeight = 0,
        clientHeight = 0,
      } = chatBodyRef.current;
      if (scrollTop === 0 && hasMoreMessages && !isLoadingMore) {
        loadMoreMessages();
      }
      // Check if user is at bottom
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };

  const loadMoreMessages = async () => {
    setIsLoadingMore(true);
    const nextChunk = chunk + 1;

    try {
      const response = await request.get(
        `/chat/messages?chunk=${nextChunk}&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();
      scrollChatToBottom();

      if (newMessages && newMessages.length > 0) {
        setMessages((prevMessages) => [...newMessages, ...prevMessages]);

        setChunk(nextChunk);
      } else {
        setHasMoreMessages(false);
      }
    } catch (err) {
      // Handle error
    } finally {
      setIsLoadingMore(false);
    }
  };
  const fetchLatestMessages = async () => {
    if (!info?.id) return;
    try {
      const response = await request.get(
        `/chat/messages?chunk=1&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();
      setMessages(newMessages);
      scrollChatToBottom();
    } catch (err) {
      // Handle error
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchLatestMessages();
    }, 14000);

    return () => clearInterval(interval);
  }, [messages, info]);

  const handleSendMessage = async () => {
    if (inputMessage?.trim() !== "") {
      setInputMessage("");
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();

        formData.append("text", inputMessage);
        formData.append("chat_id", info?.chat?.id);
        formData.append("account_id", accountId);

        await axios.post(base_url + "/chat/message/send", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // After sending message, fetch latest messages
        fetchLatestMessages();
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      textInputRef.current.focus();
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();

        formData.append("chat_id", info?.chat?.id);
        formData.append("file", file);
        formData.append("account_id", accountId);

        await axios.post(base_url + "/chat/message/send", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // After sending file, fetch latest messages
        fetchLatestMessages();
        setIsLoading(false);
      } catch (error) {
        toast.error("Ошика!");
        setIsLoading(false);
      }
    }
  };
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      // echoCancellation: true,
      encodingType: "audio/ogg",
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const addAudioElement = async (blob) => {
    const audioFile = new File([blob], "audio_message.ogg", {
      type: "audio/ogg",
    });
    await sendAudioFile(audioFile);
  };

  const sendAudioFile = async (file) => {
    if (!file) return;

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();

      formData.append("chat_id", info?.chat?.id);
      formData.append("account_id", accountId);
      formData.append("file", file);
      await axios.post(base_url + "/chat/message/send", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // After sending the file, fetch the latest messages
      fetchLatestMessages();
    } catch (error) {
      console.error("Error sending audio file:", error);
    }
  };

  const [managerId] = useState(["manager"].includes(user?.role) ? true : false);

  const [chats, setChats] = useState([]);
  const loadMoreData = async () => {
    try {
      const newData = await fetchData(1, managerId); // Use fetchData from request.js
      setChats(newData.data);
    } catch (error) {
      console.error("Failed to load data:", error);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, [managerId]);

  const [forwardMessageModal, setForwardMessageModal] = useState({
    open: false,
  });

  const handleForwardMessage = (
    chat_id,
    message_id = forwardMessageModal.message?.id,
    onSusccess = () => {}
  ) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();

      formData.append(
        "message_id",
        message_id || forwardMessageModal.message?.id
      );
      formData.append("chat_id", chat_id);
      formData.append("account_id", accountId);

      axios.post(base_url + "/chat/message/forward", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // After sending message, fetch latest messages
      fetchLatestMessages();
      setForwardMessageModal({ open: false });
      onSusccess();
    } catch (error) {
      toast.error("Ошибка!");
      console.error("Error:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <ChatContainer size={height}>
      {isLoading && <Loadering />}
      {forwardMessageModal.open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: 20,
              borderRadius: 10,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <strong>Forwarded Message</strong>
              <CgClose
                onClick={() => setForwardMessageModal({ open: false })}
                style={{ cursor: "pointer" }}
              />
            </div>
            {chats?.filter(
              (chat) => chat?.id !== +id && chat?.chat?.peer !== 777777
            )?.length === 0 ? (
              <NavLink
                style={{ textDecoration: "none" }}
                to={
                  "/messages/" +
                  chats?.find((chat) => chat?.chat?.peer === 777777)?.id +
                  "?account_id=" +
                  chats?.find((chat) => chat?.chat?.peer === 777777)?.chat
                    ?.account_id
                }
              >
                <NotificationCard
                  index={0}
                  onClick={() =>
                    handleForwardMessage(
                      chats?.find((chat) => chat?.chat?.peer === 777777)?.chat
                        ?.id
                    )
                  }
                >
                  <NotificationContent>
                    <UserInfo>
                      <SavedMessageIcon width={40} height={40} />

                      <UserName>Saved Messages</UserName>
                    </UserInfo>
                    <MessagePreview>
                      {
                        chats?.filter(
                          (chat) =>
                            chat?.id !== +id && chat?.chat?.peer !== 777777
                        )?.message
                      }
                    </MessagePreview>
                    <EmailPreview>
                      {
                        chats?.filter(
                          (chat) =>
                            chat?.id !== +id && chat?.chat?.peer !== 777777
                        )?.chat?.info?.phone?.phone_number
                      }
                    </EmailPreview>
                  </NotificationContent>
                </NotificationCard>
              </NavLink>
            ) : (
              // chat list
              chats
                ?.filter(
                  (chat) => chat?.id !== +id && chat?.chat?.peer !== 777777
                )
                ?.map((notification, index) => (
                  <NavLink
                    key={notification?.id}
                    style={{ textDecoration: "none" }}
                    to={
                      "/messages/" +
                      notification?.id +
                      "?account_id=" +
                      notification?.chat?.account_id
                    }
                  >
                    <NotificationCard
                      index={index}
                      onClick={() =>
                        handleForwardMessage(notification?.chat?.id)
                      }
                    >
                      <NotificationContent>
                        <UserInfo>
                          <Avatar
                            width={40}
                            height={40}
                            style={{ borderRadius: 50 }}
                            src={
                              (notification?.chat?.info?.photo?.path &&
                                storage_url +
                                  notification?.chat?.info?.photo?.path +
                                  "/" +
                                  notification?.chat?.info?.photo?.name) ||
                              "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                            }
                            alt=""
                          />

                          <UserName>
                            {notification?.chat?.info?.first_name}{" "}
                            {notification?.chat?.info?.last_name}{" "}
                            <ReplyText>ответил вам</ReplyText>
                          </UserName>
                          <TimeStamp>
                            {new Date(
                              notification?.created_at
                            ).toLocaleDateString()}
                          </TimeStamp>
                          <StatusCircle />
                          {/* {notification?.hasAttachment && <FileIcon size={16} />} */}
                        </UserInfo>
                        <MessagePreview>{notification?.message}</MessagePreview>
                        <EmailPreview>
                          {notification?.chat?.info?.phone?.phone_number}
                        </EmailPreview>
                      </NotificationContent>
                    </NotificationCard>
                  </NavLink>
                ))
            )}
          </div>
        </div>
      )}
      <ChatHeader>
        <ChatHeaderAvatar>
          {info?.chat?.peer === 777777 ? (
            <SavedMessageIcon width={"70%"} height={"70%"} />
          ) : (
            <img
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: 50 }}
              src={
                (info?.chat?.info?.photo?.path &&
                  storage_url +
                    info?.chat?.info?.photo?.path +
                    "/" +
                    info?.chat?.info?.photo?.name) ||
                "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
              }
              alt="chat"
            />
          )}
        </ChatHeaderAvatar>
        <ChatHeaderName>
          {info?.chat?.peer === 777777
            ? "Saved Messages"
            : `${info?.chat?.info?.first_name || "Пользователь"} ${
                info?.chat?.info?.last_name || ""
              }`}
        </ChatHeaderName>
      </ChatHeader>
      <ChatBody ref={chatBodyRef} onScroll={handleScroll}>
        {isLoadingMore && (
          <div style={{ padding: 10, textAlign: "center" }}>
            <LoadIcon>
              <LoaderIcon />
            </LoadIcon>
          </div>
        )}
        {messages?.map((message, index) => (
          <MessageContainer
            key={message?.id}
            isUser={message?.action === 200}
            index={index}
          >
            {message?.action === 100 && (
              <AvatarIsUser
                src={
                  (info?.chat?.info?.photo?.path &&
                    storage_url +
                      info?.chat?.info?.photo?.path +
                      "/" +
                      info?.chat?.info?.photo?.name) ||
                  "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                }
                alt="User avatar"
              />
            )}

            <MessageContent isUser={message?.action === 200}>
              <ForwardMessageContainer
                isUser={message?.action === 200}
                onClick={() =>
                  setForwardMessageModal({
                    open: true,
                    message,
                  })
                }
              >
                <ForwardIcon size={16} />
              </ForwardMessageContainer>
              {info?.chat?.peer === 777777 ? null : (
                <ForwardMessageContainer
                  style={{
                    top: 40,
                  }}
                  isUser={message?.action === 200}
                  onClick={() => {
                    handleForwardMessage(
                      chats?.find((chat) => chat?.chat?.peer === 777777)?.chat
                        ?.id,
                      message?.id,
                      () => {
                        toast.success(
                          "Сообщение отправлено в 'Сохраненные сообщения'"
                        );
                        window.confirm("Перейти к нему?") &&
                          navigate(
                            `/messages/${
                              chats?.find((chat) => chat?.chat?.peer === 777777)
                                ?.id
                            }?account_id=${
                              chats?.find((chat) => chat?.chat?.peer === 777777)
                                ?.chat?.account_id
                            }`
                          );
                      }
                    );
                  }}
                >
                  <SavedMessageIcon width={16} height={16} />
                </ForwardMessageContainer>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  if (
                    message?.photo ||
                    message?.document ||
                    message?.voice ||
                    message?.audio ||
                    message?.video
                  ) {
                    // full screen
                    setIsFullScreen(
                      message?.id === isFullScreen ? null : message?.id
                    );
                  }
                }}
              >
                {message?.photo && (
                  <img
                    src={file_url + message?.photo}
                    alt={message?.photo}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      cursor: "pointer",
                      objectFit: "contain",
                    }}
                  />
                )}
                {message?.document && (
                  <a
                    href={file_url + message?.document}
                    alt={message?.document}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      textDecoration: "none",
                      color: "black",
                    }}
                    target="_blank"
                  >
                    <img
                      src="https://images.freeimages.com/fic/images/icons/2813/flat_jewels/512/file.png"
                      alt="file"
                      width={30}
                      height={30}
                    />
                    {message?.document.slice(
                      message?.document?.lastIndexOf("/") + 1
                    )}
                  </a>
                )}
                {message?.voice && (
                  <audio
                    src={file_url + message?.voice}
                    controls
                    muted={isFullScreen}
                  ></audio>
                )}
                {message?.audio && (
                  <audio
                    src={file_url + message?.audio}
                    controls
                    muted={isFullScreen}
                  ></audio>
                )}
                {message?.video && (
                  <video
                    src={file_url + message?.video}
                    controls={message?.type === 300 ? false : true}
                    loop={message?.type === 300}
                    width={"100%"}
                    autoPlay={message?.type === 300}
                    style={{
                      maxHeight: 400,
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    muted={isFullScreen}
                  ></video>
                )}
                {message?.text && (
                  <MessageText
                    dangerouslySetInnerHTML={{ __html: message?.text }}
                  ></MessageText>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 999,
                  background: "rgba(0, 0, 0, 0.5)",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 20,
                  gap: 10,
                  overflow: "auto",
                  cursor: "pointer",
                  transition: "all 0.2s",
                  scale: isFullScreen === message?.id ? "1" : "0.9",
                  opacity: isFullScreen === message?.id ? 1 : 0,
                  pointerEvents: isFullScreen === message?.id ? "auto" : "none",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    cursor: "pointer",
                    color: "white",
                    fontSize: 50,
                    zIndex: 999,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: 50,
                    padding: 10,
                    transition: "all 0.2s",
                    width: 50,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => setIsFullScreen(null)}
                >
                  <CgClose />
                </div>
                {message?.photo && (
                  <img
                    src={file_url + message?.photo}
                    alt={message?.photo}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
                {message?.document && (
                  <a
                    href={file_url + message?.document}
                    alt={message?.document}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      textDecoration: "none",
                      color: "black",
                    }}
                    target="_blank"
                  >
                    <img
                      src="https://images.freeimages.com/fic/images/icons/2813/flat_jewels/512/file.png"
                      alt="file"
                      width={30}
                      height={30}
                    />
                    {message?.document.slice(
                      message?.document?.lastIndexOf("/") + 1
                    )}
                  </a>
                )}
                {message?.voice && (
                  <audio
                    src={file_url + message?.voice}
                    controls
                    muted={isFullScreen !== message?.id}
                  ></audio>
                )}
                {message?.audio && (
                  <audio
                    src={file_url + message?.audio}
                    controls
                    muted={isFullScreen !== message?.id}
                  ></audio>
                )}
                {message?.video && (
                  <video
                    src={file_url + message?.video}
                    controls={message?.type === 300 ? false : true}
                    loop={message?.type === 300}
                    width={"100%"}
                    autoPlay={message?.type === 300}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    muted={isFullScreen !== message?.id}
                  ></video>
                )}
              </div>

              <MessageTime>{message?.created_at?.slice(11, 16)}</MessageTime>
            </MessageContent>
            {message?.action === 200 && (
              <Avatar
                src={
                  "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                }
                alt="User avatar"
              />
            )}
          </MessageContainer>
        ))}
      </ChatBody>
      {["owner"].includes(user?.role) && (
        <ChatInputArea>
          <IconButton onClick={() => fileInputRef.current.click()}>
            <Paperclip size={24} />
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />

          <IconButton onClick={() => fileInputRef.current.click()}>
            <Image size={24} />
          </IconButton>
          {/* <AudioRecorder
            onRecordingComplete={(blob) => addAudioElement(blob)}
            recorderControls={recorderControls}
            // downloadOnSavePress={true}
            // downloadFileExtension="wav"
            showVisualizer={true}
          /> */}
          <OggRecorder
            chat_id={info?.chat?.id}
            accountId={accountId}
            fetchLatestMessages={fetchLatestMessages}
          />
          <ChatInput
            ref={textInputRef}
            placeholder="Type a message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          />

          <SendButton onClick={handleSendMessage}>Отправить</SendButton>
        </ChatInputArea>
      )}
    </ChatContainer>
  );
}

export default Dialogue;